import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import BalfourBeattyHeader from '../CommonTips/BalfourBeattyHeader';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode, tenderTaskStatus } from '../Helper/CommonThings';
import { CoffeeLoading } from 'react-loadingg';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const useStyles = (theme) => ({


});

var mailtype = 'request'
var approvalIndex = 0
var progressPerDone = 0

class BulkApproval extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],

            openCal: false,
            toDia: [[], []],
            toDia2: [[], []],
            isLoading: true,
            selectDate: new Date(),
            openSub: false,
            adddata: [],
            postComment: '',
            is_approved: true,
            is_reject: true,
            enableDelete: false,
            notifyUser: true,
            progress: 0,
            buffer: 0,
            countDone: 0,
            dataToApprove: []
        }

    }


    componentDidMount() {
        approvalIndex = 0
        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!token) {
            window.location.href = '/'
        } else {
            if (!(access && access.access && access.access.l2a && access.access.l2a.l2a_b)) {
                alert("You don't have approver access.")
            } else {
                this.fetchOps();
            }
        }
        // progressPerDone = 100 / this.props.value.length
    }

    fetchOps() {
        let ids = []
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            if (element) {
                ids.push(element.id)
            }
        }
        this.goToApprove(ids)

        // Service.get(`/bb/api/task/approve/approval_data/?approval_id=${ids.toString()}`, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         for (let j = 0; j < res.data.length; j++) {
        //             const item = res.data[j];
        //             let toId = "";
        //             for (let i = 0; i < item.list_tasks[1][1].length; i++) {
        //                 const element = item.list_tasks[1][1][i];
        //                 if (i === 0) {
        //                     toId = "" + element.id;
        //                 } else {
        //                     toId = toId + "," + element.id;
        //                 }
        //             }
        //             let dataFr = item;

        //             if (item.request_to !== user.email) {
        //                 if (!(access && access.access && access.access.l2a && access.access.l2a.l2a_c)) {
        //                     alert("User not authenticated.")
        //                     window.location.href = '/'
        //                 }

        //             }

        //             if (item.approve_status === 0) {
        //                 Service.get("/bb/api/team/tasks/?id=" + toId, {
        //                     headers: {
        //                         Authorization: "Token " + token,
        //                     },
        //                 })
        //                     .then(res => {
        //                         var datapull = [];
        //                         for (let i = 0; i < res.data.length; i++) {
        //                             const element = res.data[i];
        //                             var date = moment(element.start).format('DD/MM/YYYY')
        //                             var stime = moment(element.start).format('HH mm A');
        //                             var etime = moment(element.end).format('HH mm A');
        //                             var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                             var whours1 = ''
        //                             //console.log(whours.asHours())
        //                             if (parseFloat(whours.asHours()) < 0.9) {
        //                                 whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                             } else {
        //                                 whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                             }

        //                             if (element.is_tender === 1) {
        //                                 datapull.push({
        //                                     id: element.id,
        //                                     uid: element.uid,
        //                                     gid: element.gid,
        //                                     tid: element.tid,
        //                                     pid: element.pid,
        //                                     ttid: element.ttid,
        //                                     tpid: element.tpid,
        //                                     twbsid: element.twbsid,
        //                                     trolesid: element.trolesid,
        //                                     tmapid: element.tmapid,
        //                                     uemail: element.uemail,
        //                                     uname: element.uname_first + " " + element.uname_last,
        //                                     gname: element.gname,
        //                                     tname: element.tname,
        //                                     tproj_name: element.tproj_name,
        //                                     ttname: element.ttname,
        //                                     ttdecs: element.ttdecs,
        //                                     tuemail: element.tuemail,
        //                                     twbsname: element.twbsname,
        //                                     trolename: element.trolename,
        //                                     ttender_name: element.ttender_name,
        //                                     title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
        //                                     proj_name: element.proj_name,
        //                                     work_detail: element.work_detail,
        //                                     comments: element.comments,
        //                                     date: date,
        //                                     start: new Date(element.start),
        //                                     end: new Date(element.end),
        //                                     is_locked: element.is_locked,
        //                                     is_submitted: element.is_submitted,
        //                                     is_approved: element.is_approved,
        //                                     strstart: element.start,
        //                                     strend: element.end,
        //                                     stime: stime,
        //                                     etime: etime,
        //                                     estimated_hours: element.estimated_hours,
        //                                     created_by: element.created_by,
        //                                     whours: whours1,
        //                                     actual_hours: element.actual_hours,
        //                                     is_tender: element.is_tender,
        //                                     task_hold: element.task_hold,
        //                                     ttstatus: element.ttstatus,
        //                                     ttender_id: element.ttenderid,
        //                                 });
        //                             } else if (element.is_tender === 0) {
        //                                 datapull.push({
        //                                     id: element.id,
        //                                     uid: element.uid,
        //                                     gid: element.gid,
        //                                     tid: element.tid,
        //                                     pid: element.pid,
        //                                     ttid: element.ttid,
        //                                     tpid: element.tpid,
        //                                     twbsid: element.twbsid,
        //                                     trolesid: element.trolesid,
        //                                     tmapid: element.tmapid,
        //                                     uemail: element.uemail,
        //                                     uname: element.uname_first + " " + element.uname_last,
        //                                     gname: element.gname,
        //                                     tname: element.tname,
        //                                     tproj_name: element.tproj_name,
        //                                     ttname: element.ttname,
        //                                     ttdecs: element.ttdecs,
        //                                     tuemail: element.tuemail,
        //                                     twbsname: element.twbsname,
        //                                     trolename: element.trolename,
        //                                     ttender_name: element.ttender_name,
        //                                     title: element.proj_name + "(" + element.tname + ")",
        //                                     proj_name: element.proj_name,
        //                                     work_detail: element.work_detail,
        //                                     comments: element.comments,
        //                                     date: date,
        //                                     start: new Date(element.start),
        //                                     end: new Date(element.end),
        //                                     is_locked: element.is_locked,
        //                                     is_submitted: element.is_submitted,
        //                                     is_approved: element.is_approved,
        //                                     strstart: element.start,
        //                                     strend: element.end,
        //                                     stime: stime,
        //                                     etime: etime,
        //                                     estimated_hours: element.estimated_hours,
        //                                     created_by: element.created_by,
        //                                     whours: whours1,
        //                                     actual_hours: element.actual_hours,
        //                                     is_tender: element.is_tender,
        //                                     task_hold: element.task_hold,
        //                                     ttstatus: element.ttstatus,
        //                                     ttender_id: element.ttenderid,
        //                                 });
        //                             }
        //                         }

        //                         var enableDelete = false
        //                         if (datapull.length === 0 && (dataFr.request_to_id === user.id || dataFr.uid === user.id)) {
        //                             enableDelete = true
        //                         }
        //                         //console.log(res.data)
        //                         this.setState({ data: dataFr, data1: datapull, enableDelete: enableDelete }, () => {
        //                             this.handleColumns()

        //                         })
        //                     })
        //                     .catch(error => {
        //                         // console.log(error)
        //                         errorMessage(this.props, error)
        //                     });
        //             } else {
        //                 this.setState({ data: dataFr, data1: dataFr.list_tasks[1][1], }, () => {
        //                     this.handleColumns()
        //                 })
        //             }
        //         }
        //     })
        //     .catch(error => {
        //         // console.log(error)
        //         errorMessage(this.props, error)
        //     });
    }

    handleColumns() {

        var columns = [
            { title: 'Emp Name', field: 'uname' },

            // { title: 'Projects', field: 'proj_name' },
            // { title: 'Tasks', field: 'tname' },
        ];

        if (this.state.data.list_tasks[0][0][1].field !== 'comment') {
            columns.push({ title: 'Comment', field: 'comment' });
        }

        for (let i = 1; i < this.state.data.list_tasks[0][0].length; i++) {
            const element = this.state.data.list_tasks[0][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[element.field]) < 4) ? ("error") : (parseInt(rowData[element.field]) > 7) ? ("primary") : ("secondary")} >{rowData[element.field]}</Typography></ThemeProvider>
                }
            )
        }

        var statedata = this.state.data1;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            element[element.date] = element.whours;

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name + " - " + element3.tproj_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === element3.ttname) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttname)
                            }

                        }
                    }
                    // console.log(hours)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element[0].pid,
                tid: element[0].tid,
                ttid: element[0].ttid,
                tpid: element[0].tpid,
                twbsid: element[0].twbsid,
                trolesid: element[0].trolesid,
                tmapid: element[0].tmapid,
                tname: tasks.join(),
                tproj_name: element[0].tproj_name,
                ttname: element[0].ttname,
                tuemail: element[0].tuemail,
                twbsname: element[0].twbsname,
                trolename: element[0].trolename,
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours,
                created_by: element[0].created_by,
                is_tender: element.is_tender,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            const totalDurations11 = wh.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh[0]));
            var minin = (moment.duration(totalDurations11.asMilliseconds()));
            var minin2 = moment.utc(totalDurations11.asMilliseconds()).format("mm")
            jsoncons["total"] = parseInt(minin.asHours()) + ":" + minin2;

            toTData.push(jsoncons);
        }

        var todata = [];
        // console.log(columns)
        todata.push(columns)
        todata.push(toTData)
        // console.log(todata)
        this.setState({
            toDia: todata,
        }, () => {
            this.handleColumns2()
        })

    }

    handleColumns2() {
        var columns = [
            {
                title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.proj_name ? rowData.proj_name : rowData.ttender_name + " - " + rowData.tproj_name + " - " + rowData.twbsname)
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.tname ? rowData.tname : rowData.ttdecs ? rowData.ttdecs : rowData.ttname)
            },
            {
                title: 'Status', field: 'task_hold',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus)
            },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Hours Expended', field: 'actual_hours' },
        ];
        var i_temp = 6
        for (let i = 0; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            if (element.field === 'actual_hours') {
                i_temp = i + 1
            }
        }
        for (let i = i_temp; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <Typography color={("primary")}>{rowData[element.field]}</Typography>
                }
            )
        }

        var todata = [];
        todata.push(columns);

        var is_approve = true;
        var is_reject = true;
        for (let i = 0; i < this.state.data1.length; i++) {
            const element = this.state.data1[i];

            element[element.date] = element.whours

            //console.log(element.is_approved)
            if (element.is_approved !== 1) {
                is_approve = false;
            }
            if (element.is_approved !== 2) {
                is_reject = false;
            }
        }

        todata.push(this.state.data1)

        // console.log(todata)

        this.setState({
            toDia2: todata,
            is_approved: is_approve,
            is_reject: is_reject,
            isLoading: false
        }, () => {
            this.autoApprove()
        })

    }

    autoApprove() {
        this.handleApprove(1)
    }

    handleMail(to, sub, body, reply_to) {
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {

                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    handleReview() {

        var sub = user.last_name + ", " + user.first_name + " requested you to review your timesheet"
        var body = "Hi " + this.state.data.uname + "\n   You need to do the following comment\n\n  Comment : " + this.state.postComment +
            "\n\n Note : If you edit the existing task, no need to submit timesheet for approval again. But, if you add any new tasks, then please submit for approval." +
            "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id

        this.handleMail(this.state.data.uemail, sub, body, user.email)

        this.setState({ openSub: false })
    }

    handleRejectMail() {

        var sub = user.last_name + ", " + user.first_name + " rejected your timesheet"
        var body = "Hi " + this.state.data.uname + "\n   You need to do the following comment\n\n  Comment : " + this.state.postComment +
            "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id


        if (this.state.notifyUser) {
            this.handleMail(this.state.data.uemail, sub, body, user.email)
        }

        this.setState({ openSub: false })
    }

    handleApproveMail() {

        // var sub = user.last_name + ", " + user.first_name + " approved your timesheet"
        // var body = "Hi " + this.state.data.uname + "\n   Your Timesheet approved by " + user.last_name + ", " + user.first_name +
        //     "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id

        // if (this.state.notifyUser) {
        //     alert("qw")
        //     this.handleMail(this.state.data.uemail, sub, body, user.email)
        // }

        this.setState({ openSub: false })
    }

    handleCommSubmit() {
        if (mailtype === 'request') {
            this.handleReview();
        } else if (mailtype === 'reject') {
            this.handleApprove(2); //actually rejecting
            this.setState({ openSub: false, isLoading: true })
        }
    }

    handleApprove(stat) {
        this.setState({ isLoading: true })
        var dataTo = [];
        dataTo.push(this.state.toDia);
        dataTo.push(this.state.toDia2);
        var upTask = [];

        var postData = this.state.data;
        postData.replied_by = user.id;
        postData.replied_on = new Date();
        postData.list_tasks = dataTo;
        postData.approve_status = stat;

        upTask.push(postData)

        for (let i = 0; i < this.state.data1.length; i++) {
            const element = this.state.data1[i];
            upTask.push(element.id)
        }

        let dataToApprove = this.state.dataToApprove
        dataToApprove.push(upTask)
        this.setState({ dataToApprove })

        this.setState({ countDone: this.state.countDone + 1, }, () => {
            this.progressProcess()
            ++approvalIndex
            if (approvalIndex === this.props.value.length) {
                this.goToApprove(stat)
            }
            // else {
            //     this.fetchOps(approvalIndex);
            // }
        })

        // var url = ""
        // if (stat === 1) {
        //     url = "/bb/api/task/approve/approve_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        // } else if (stat === 2) {
        //     url = "/bb/api/task/approve/reject_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        // }

        // Service.patch(url, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: upTask,
        // })
        //     .then(res => {
        //         // this.handleApproveMail();
        //         if (this.props.refreshCount) {
        //             this.props.refreshCount()
        //         }
        //         this.setState({ countDone: this.state.countDone + 1, }, () => {
        //             this.progressProcess()
        //             ++approvalIndex
        //             if (approvalIndex === this.props.value.length) {
        //                 setTimeout(() => {
        //                     window.location.reload()
        //                 }, 1000)
        //             } else {
        //                 this.fetchOps(approvalIndex);
        //             }


        //         })

        //     })
        //     .catch(error => {
        //         this.setState({ isLoading: false })
        //         errorMessage(this.props, error)
        //     });

    }

    goToApprove(ids) {
        var url = '/bb/api/task/approve/bulk_approve_based_on_timesheet_layout/?notify=1'
        // if (stat === 1) {
        //     url = "/bb/api/task/approve/approve_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        // } else if (stat === 2) {
        //     url = "/bb/api/task/approve/reject_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        // }

        Service.patch(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: ids,
        })
            .then(res => {
                if (this.props.refreshCount) {
                    this.props.refreshCount()
                }
                if (this.props.closeBulkApproval) {
                    this.props.closeBulkApproval()
                }
                this.props.enqueueSnackbar('Bulk approval done successfully!', {
                    variant: 'success'
                });
                // approvalIndex = 0
                // this.setState({ dataToApprove: [] })
            })
            .catch(error => {
                if (this.props.closeBulkApproval) {
                    this.props.closeBulkApproval()
                }
                this.setState({ isLoading: false })
                errorMessage(this.props, error)
            });
    }

    progressProcess() {
        if (this.state.progress > 100) {
            this.setState({
                progress: 100,
                buffer: 100
            })
        } else {
            const diff = progressPerDone * this.state.countDone;
            const diff2 = progressPerDone * (this.state.countDone + 1);
            this.setState({
                progress: this.state.progress + diff,
                buffer: this.state.progress + diff + diff2
            })
            // setProgress(progress + diff);
            // setBuffer(progress + diff + diff2);
        }
    }


    render() {

        return (
            <div>

                <Box display="flex" alignItems="center">
                    <Box m={5} p={2}>
                        <CoffeeLoading />
                    </Box>
                    {/* <Box width="100%" mr={1}>
                        <LinearProgress variant="buffer" value={this.state.progress} valueBuffer={this.state.buffer} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{this.state.countDone + " / " + this.props.value.length}</Typography>
                    </Box> */}
                </Box>
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(BulkApproval));