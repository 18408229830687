import React, { Component } from 'react';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, DialogTitle, TextField } from '@material-ui/core';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Autocomplete } from '@material-ui/lab';

const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

class OverHeadsChangeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            disableEdit: this.props.disableEdit,
            tasks: [],
            columns: [],
            isLoading: true,
            openDialog: true,
            showBtn: false,
            postArray: [],
            users: [],
            confirmDialog: false,
            usersLookup: {},
            copyTasks: [],
            btnDisabled: false,
            bulkEditValue: 0,
            bulkEditColumn: null,
            countryFilter: null,
            searchValue: '',
            saveDisabled: false,
            taskCategory: null,
            categorySelected: null,
            teams: [],
            selectedTeam: null,
            submitDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchCategory()
    }

    fetchCategory() {
        Service.get("/bb/api/tasks/estimate/get_task_category_ids/?pid=11", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ taskCategory: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        if (user) {
            Service.get(`/bb/api/user/report/view/get_lead_in_teams/?uid=${user.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ teams: res.data, isLoading: false }, () => {
                        if (this.state.teams.length === 1) {
                            this.setState({ selectedTeam: this.state.teams[0] })
                        }
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    submit() {
        if (!this.state.categorySelected) {
            this.props.enqueueSnackbar('Please select a category!', {
                variant: 'warning'
            })
        } else if (!this.state.selectedTeam && this.state.teams.length > 1) {
            this.props.enqueueSnackbar('Please select a team!', {
                variant: 'warning'
            })
        } else if (this.state.categorySelected && this.state.teams.length === 1) {
            this.fetchData(this.state.categorySelected, this.state.teams[0])
        } else {
            this.fetchData(this.state.categorySelected, this.state.selectedTeam)
        }
    }

    fetchData(category, team) {
        this.setState({ isLoading: true, submitDisabled: true })
        Promise.all([
            this.fetchTasks(category, team),
            this.fetchColumns(category)
        ])
            .then(res => {
                let usersLookup = {}
                for (let i = 0; i < res[0].length; i++) {
                    const element = res[0][i];
                    usersLookup[element.id] = element.last_name + ", " + element.first_name
                }
                this.setState({
                    tasks: res[0].filter((task_item) => {
                        if (this.state.countryFilter === null) {
                            return true
                        } else if (this.state.countryFilter === task_item.office_loc) {
                            return true
                        }
                        return false
                    }),
                    copyTasks: res[0],
                    columns: res[1],
                    isLoading: false,
                    usersLookup,
                    showBtn: false,
                    postArray: [],
                    bulkEditColumn: null,
                    bulkEditValue: 0,
                    submitDisabled: false,
                    users: []
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    fetchTasks(category, team) {
        return new Promise((resolve, reject) => {
            Service.get(`/bb/api/tasks/estimate/task_estimate/?year=${this.props.year}&task_category=${category.id}&team_id=${team.team_id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    fetchColumns(category) {
        return new Promise((resolve, reject) => {
            Service.get(`/bb/api/tasks/?hours_control=${true}&task_category=${category.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    handleSearch(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.copyTasks
        }

        for (let i = 0; i < this.state.copyTasks.length; i++) {
            const element = this.state.copyTasks[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.search_key.includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({
            tasks: filtered_data.filter((task_item) => {
                if (this.state.countryFilter === null) {
                    return true
                } else if (this.state.countryFilter === task_item.office_loc) {
                    return true
                }
                return false
            }),
            searchValue: data,
            disableEdit: true
        }, () => {this.setState({ disableEdit: false })})
    }

    handleChange(event, data, key, index) {

        var number = Number(event.target.value)
        var tasks = this.state.tasks
        tasks[index][key + "_estimate"] = (number < 0) ? 0 : number
        this.setState({ showBtn: true, tasks })
        var postArray = this.state.postArray
        var users = this.state.users
        if (postArray.length === 0) {
            if (number < Number(0)) {
                number = Number(0)
            }
            users.push(data.id)
            postArray.push({
                user_id: data.id,
                [key]: number
            })
        } else {
            if (number < Number(0)) {
                number = Number(0)
            }
            for (let i = 0; i < postArray.length; i++) {
                const element = postArray[i];
                if (element.user_id === data.id) {
                    element[key] = number
                } else if (!users.includes(data.id)) {
                    users.push(data.id)
                    postArray.push({
                        user_id: data.id,
                        [key]: number
                    })
                }
            }
        }
        this.setState({ users, postArray }, () => {
            this.setState({ saveDisabled: this.errorCheckBeforeSave() })
        })
    }

    putData(postData) {
        if (postData.length > 0) {
            this.setState({ btnDisabled: true })
            let data = {
                year: this.props.year,
                array: postData
            }
            Service.put(`/bb/api/tasks/estimate/task_estimate_edit/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.setState({
                        users: [],
                        postArray: [],
                        showBtn: false,
                        confirmDialog: false,
                        btnDisabled: false,
                        bulkEditColumn: null
                    })
                    this.submit()
                    this.props.enqueueSnackbar('Datas updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Please edit something to save!', {
                variant: 'warning'
            });
        }
    }

    discardChanges() {
        this.submit()
        // this.setState({
        //     showBtn: false,
        //     tasks: this.state.copyTasks,
        //     isLoading: true,
        //     postArray: [],
        //     users: [],
        //     bulkEditColumn: null,
        //     bulkEditValue: 0
        // }, () => {
        //     setTimeout(() => {
        //         this.setState({ isLoading: false })
        //     }, 100);
        // })
    }

    bulkEdit() {
        this.setState({ bulkEdit: false, isLoading: true, showBtn: true })
        let users = this.state.users
        let postArray = this.state.postArray
        var tasks = this.state.tasks
        if (postArray.length === 0) {
            for (let i = 0; i < tasks.length; i++) {
                const element = tasks[i];
                // users.push(element.id)
                postArray.push({
                    user_id: element.id,
                    [this.state.bulkEditColumn.id]: this.state.bulkEditValue
                })
                element[this.state.bulkEditColumn.id + "_estimate"] = this.state.bulkEditValue
            }
        } else {
            for (let i = 0; i < postArray.length; i++) {
                const element = postArray[i];
                element[this.state.bulkEditColumn.id] = this.state.bulkEditValue
            }
            for (let i = 0; i < tasks.length; i++) {
                const element = tasks[i];
                element[this.state.bulkEditColumn.id + "_estimate"] = this.state.bulkEditValue
            }
        }

        this.setState({ showBtn: true, tasks, users, postArray, isLoading: false, bulkEditColumn: null }, () => {
            this.setState({ saveDisabled: this.errorCheckBeforeSave() })
        })
    }

    openBulkEdit(item) {
        this.setState({ bulkEditColumn: item, bulkEdit: true, bulkEditValue: 0 })
    }

    handleBulkChange(e) {
        var number = Number(e.target.value)
        if (number < Number(0)) {
            number = Number(0)
        }
        let users = []
        for (let i = 0; i < this.state.tasks.length; i++) {
            const element = this.state.tasks[i];
            users.push(element.id)
        }
        this.setState({ bulkEditValue: number, users })
    }

    errorCheckBeforeSave() {
        // console.log(this.state.copyTasks)
        var copyTasks = this.state.copyTasks.filter((task_item) => {
            if (this.state.countryFilter === null) {
                return true
            } else if (this.state.countryFilter === task_item.office_loc) {
                return true
            }
            return false
        })
        for (let i = 0; i < copyTasks.length; i++) {
            const element = copyTasks[i];
            for (let index = 0; index < this.state.columns.length; index++) {
                const element1 = this.state.columns[index];
                if (element[element1.id + "_actual"] && element[element1.id + "_actual"] > element[element1.id + "_estimate"]) {
                    return true
                }
            }
        }
        return false
    }


    render() {

        const handleClose = () => {
            this.setState({ confirmDialog: false })
        };

        const handleBulkEditClose = () => {
            this.setState({ bulkEdit: false, bulkEditValue: 0, bulkEditColumn: null })
        };

        const handleBCountryFilterChange = (event, value) => {
            this.setState({ countryFilter: value }, () => this.handleSearch(this.state.searchValue))
        };

        const onCategorySelect = (event, value) => {
            if (value) {
                this.setState({ categorySelected: value })
            } else {
                this.setState({ tasks: [], copyTasks: [], categorySelected: null })
            }
        };

        const onTeamSelect = (event, value) => {
            if (value) {
                this.setState({ selectedTeam: value })
            } else {
                this.setState({ tasks: [], copyTasks: [], selectedTeam: null })
            }
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <div>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} mb={1}>
                            <Autocomplete
                                id="combo-box-demot4"
                                options={this.state.taskCategory}
                                value={this.state.categorySelected}
                                getOptionLabel={(option) => option.name}
                                onChange={onCategorySelect}
                                size='small'
                                style={{ width: 250, marginRight: '10px' }}
                                renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                            />

                            {this.state.teams.length > 1 ?
                                <Autocomplete
                                    id="combo-box-demot5"
                                    options={this.state.teams}
                                    value={this.state.selectedTeam}
                                    getOptionLabel={(option) => option.team_name}
                                    onChange={onTeamSelect}
                                    size='small'
                                    style={{ width: 250, marginRight: '10px' }}
                                    renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" />}
                                /> : null}

                            <Button color='primary' variant='contained' onClick={() => this.submit()} style={{ marginRight: '10px' }}>
                                Submit
                            </Button>

                            {this.state.copyTasks.length > 0 ?
                                <ToggleButtonGroup
                                    color="primary"
                                    size='small'
                                    value={this.state.countryFilter}
                                    exclusive
                                    onChange={handleBCountryFilterChange}
                                    aria-label="Platform"
                                    style={{ marginRight: '10px' }}
                                >
                                    <ToggleButton value={null}>All</ToggleButton>
                                    <ToggleButton value={0}>UK</ToggleButton>
                                    <ToggleButton value={1}>INDIA</ToggleButton>
                                </ToggleButtonGroup>
                                : null}

                            {this.state.copyTasks.length > 0 ?
                                <TextField
                                    variant='outlined'
                                    autoComplete='off'
                                    size='small'
                                    placeholder="Search"
                                    id="search"
                                    value={this.state.searchValue}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                    }}
                                />
                                : null}

                            {this.state.showBtn ?
                                <>
                                    <Button size='small' color='primary' variant='contained' style={{ marginLeft: '10px' }} onClick={() => this.discardChanges()}>
                                        Discard
                                    </Button>
                                    <Button onClick={() => this.setState({ confirmDialog: true })} size='small' color='primary' variant='contained' style={{ marginLeft: '10px' }}>
                                        Save
                                    </Button>
                                    {this.state.saveDisabled ? <Alert style={{ marginLeft: "10px" }} severity="error">There are some error — check it out!</Alert> : null}
                                </> : null}
                        </Box>
                        {this.state.tasks.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Team</TableCell>
                                            {this.state.columns.map((item, i) =>
                                                <TableCell align="center"  >
                                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignContent={'center'} width={"100%"}>
                                                        <Box marginRight={'5px'}>
                                                            {item.name}
                                                        </Box>
                                                        {this.state.disableEdit ? null :
                                                            <EditIcon fontSize='small' style={{ cursor: "pointer" }} onClick={() => this.openBulkEdit(item)} />}
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tasks.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" >
                                                    <Box sx={{
                                                        alignItems: 'stretch',
                                                        flexDirection: 'row',
                                                    }}>
                                                        {row.first_name + " " + row.last_name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    <Box sx={{
                                                        alignItems: 'stretch',
                                                        flexDirection: 'row',
                                                    }}>
                                                        {row.team_name}
                                                    </Box>
                                                </TableCell>
                                                {this.state.columns.map((value, index) =>
                                                    <TableCell align="right" key={index}>
                                                        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                                                            <Box >
                                                                {row[value.id + "_actual"]}
                                                            </Box>
                                                            <Box marginLeft={"5px"} marginRight={'5px'}>
                                                                {(row[value.id + "_estimate"] || this.props.year >= 2023) ? "/" : null}
                                                            </Box>
                                                            <Box >
                                                                {this.state.disableEdit ?
                                                                    <Box >
                                                                        {row[value.id + "_estimate"] ? row[value.id + "_estimate"] : null}
                                                                    </Box> :
                                                                    <TextField
                                                                        helperText={row[value.id + "_actual"] > row[value.id + "_estimate"] ? ((row[value.id + "_actual"] ? row[value.id + "_actual"] : 0) + " < " + (row[value.id + "_estimate"] ? row[value.id + "_estimate"] : 0)) : null}
                                                                        error={(row[value.id + "_estimate"]) < (row[value.id + "_actual"])}
                                                                        id={"demo-helper-text-aligned-no-helper" + row.id}
                                                                        type={'number'}
                                                                        // variant='outlined'
                                                                        fontSize={screenSizeCheck() ? '8px' : '10px'}
                                                                        style={{ width: '50px' }}
                                                                        margin='dense'
                                                                        value={row[value.id + "_estimate"]}
                                                                        onChange={(e) => this.handleChange(e, row, value.id, i)}
                                                                    />}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : null}

                    </div >}

                <Dialog open={this.state.confirmDialog} fullWidth maxWidth='sm' aria-labelledby="customized-dialog-title">
                    <DialogTitle>
                        You have changed the user details given below.
                        Do you want to save?
                    </DialogTitle>
                    <DialogContent>
                        <ol>
                            {this.state.users.map((value, i) =>
                                <li>
                                    {this.state.usersLookup[value]}
                                </li>
                            )}
                        </ol>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={handleClose}>
                            no
                        </Button>
                        <Button disabled={this.state.btnDisabled} color='primary' onClick={() => this.putData(this.state.postArray)}>
                            yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.bulkEdit} fullWidth maxWidth='xs' aria-labelledby="customized-dialog-title">
                    <DialogTitle>
                        Bulk edit column - {this.state.bulkEditColumn ? this.state.bulkEditColumn.name : null}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="demo-helper-text-aligned-no-helper"
                            type={'number'}
                            variant='outlined'
                            margin='dense'
                            value={this.state.bulkEditValue}
                            onChange={(e) => this.handleBulkChange(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={handleBulkEditClose}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.btnDisabled} color='primary' onClick={() => this.bulkEdit()}>
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withSnackbar(OverHeadsChangeTable);