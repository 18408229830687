import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie, setCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';

var fetchDone ={}

export default class NewPaRaM extends Component {

  componentDidMount() {
  
    this.getAccessImdt(getCookie("bb_ts_token"))
    this.getUser(getCookie("bb_ts_token"))
    
  }

  getAccessImdt(token) {
    // var access = getCookie("bb_ts_access")
    Service.get(`/bb/api/token/access/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        if (res.data && res.data[0]) {
          fetchDone.one = true
          if (getCookie("bb_ts_access")) {
            if (JSON.parse(getCookie("bb_ts_access")).last_updated !== res.data[0].last_updated) {
              setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            }
          } else {
            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
          }

        } else {
          setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });

        }
        if (fetchDone.one && fetchDone.two) {
          setTimeout(() => {
            window.location.href = "https://bbparam.com/"
          }, 500);
        }
      })
      .catch(error => {

        setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
      });

  }

  getUser(token) {
    Service.get(`/rest-auth/user/`, {
      headers: {
        Authorization: "Token " + token
      },
    })
      .then(res => {
        // console.log(res.data)
        fetchDone.two = true

        setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
        // doneApi = true;
        localStorage.setItem("load_got_user", true);
        if (fetchDone.one && fetchDone.two) {
          setTimeout(() => {
            window.location.href = "https://bbparam.com/"
          }, 500);
        }

      })
      .catch(error => {
        // alert(erro
        errorMessage(this.props, error)

      });
  }

  render() {
    return (
      <div>Authenticating...</div>
    )
  }
}