import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box, Button, FormControlLabel, Checkbox, TextField, CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
// import BalfourBeattyHeader from '../CommonTips/BalfourBeattyHeader';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode, getPrimaryColor, tenderTaskStatus } from '../Helper/CommonThings';
import Box5 from '@mui/material/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TimesheetTemplate0 from './TimesheetTemplates/TimesheetTemplate0/TimesheetTemplate0';
import TimesheetTemplate1 from './TimesheetTemplates/TimesheetTemplate1/TimesheetTemplate1';
import TimesheetTemplate2 from './TimesheetTemplates/TimesheetTemplate2/TimesheetTemplate2';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

var autoActionAtempt = true

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const bbColorThemered = createTheme({
    palette: {
        primary: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const useStyles = (theme) => ({
});

var mailtype = 'request'
var mini_table_data_start = 2
var table_data_start = 5
class WorkHours extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data1: [],
            tabledata: [],
            rowLoading: true,
            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },
            ],
            openCal: false,
            toDia: [[], []],
            toDia2: [[], []],
            toDia3: [[], []],
            detailPanelData: [[], []],
            isLoading: true,
            selectDate: new Date(),
            openSub: false,
            adddata: [],
            postComment: '',
            is_approved: true,
            is_reject: true,
            enableDelete: false,
            notifyUser: true,
            timesheetLayout: 0,
            timesheetBillableData: null,
            approve_status: 0,
            timesheetData2: null,
            disableApprove: false,
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleIndivSelect = this.handleIndivSelect.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    componentDidMount() {
        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!token) {
            window.location.href = '/'
        }
        this.fetchOps();

        if (!(access && access.access && access.access.l2a && access.access.l2a.l2a_b)) {
            alert("You don't have approver access.")
        }
    }

    fetchOps() {
        var appid = this.props.value;
        Service.get("/bb/api/task/approve/" + appid + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                if (res.data.request_to !== user.email) {
                    if (!(access && access.access && access.access.l2a && access.access.l2a.l2a_c)) {
                        alert("User not authenticated.")
                        window.location.href = '/'
                    }
                }
                if (res.data.timesheet_layout === 2) {
                    this.setState({ data: res.data }, () => {
                        this.getTimesheetData(appid)
                    })
                } else {
                    this.setState({ timesheetLayout: res.data.timesheet_layout, approve_status: res.data.approve_status })
                    mini_table_data_start = res.data.comment ? 2 : 1
                    var toId = "";
                    for (let i = 0; i < res.data.list_tasks[1][1].length; i++) {
                        const element = res.data.list_tasks[1][1][i];
                        if (i === 0) {
                            toId = "" + element.id;
                        } else {
                            toId = toId + "," + element.id;
                        }
                    }
                    var dataFr = res.data;

                    if (res.data.approve_status === 0) {
                        Service.get("/bb/api/team/tasks/?id=" + toId, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                var datapull = [];
                                for (let i = 0; i < res.data.length; i++) {
                                    const element = res.data[i];
                                    var date = moment(element.start).format('DD/MM/YYYY')
                                    var stime = moment(element.start).format('HH mm A');
                                    var etime = moment(element.end).format('HH mm A');
                                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                                    var whours1 = ''
                                    //console.log(whours.asHours())
                                    if (parseFloat(whours.asHours()) < 0.9) {
                                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                    } else {
                                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                    }

                                    if (element.is_tender === 1) {
                                        datapull.push({
                                            id: element.id,
                                            uid: element.uid,
                                            gid: element.gid,
                                            tid: element.tid,
                                            pid: element.pid,
                                            ttid: element.ttid,
                                            tpid: element.tpid,
                                            twbsid: element.twbsid,
                                            trolesid: element.trolesid,
                                            tmapid: element.tmapid,
                                            uemail: element.uemail,
                                            uname: element.uname_first + " " + element.uname_last,
                                            gname: element.gname,
                                            tname: element.tname,
                                            tproj_name: element.tproj_name,
                                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                                            ttdecs: element.ttdecs,
                                            tuemail: element.tuemail,
                                            twbsname: element.twbsname,
                                            trolename: element.trolename,
                                            ttender_id: element.ttenderid,
                                            ttender_name: element.ttender_name,
                                            title: element.tproj_name + "(" + element.ttdecs ? element.ttdecs : element.ttname + " - " + element.ttdecs + ")",
                                            proj_name: element.proj_name,
                                            work_detail: element.work_detail,
                                            comments: element.comments,
                                            date: date,
                                            start: new Date(element.start),
                                            end: new Date(element.end),
                                            is_locked: element.is_locked,
                                            is_submitted: element.is_submitted,
                                            is_approved: element.is_approved,
                                            strstart: element.start,
                                            strend: element.end,
                                            stime: stime,
                                            etime: etime,
                                            estimated_hours: element.estimated_hours,
                                            created_by: element.created_by,
                                            whours: whours1,
                                            actual_hours: element.actual_hours,
                                            is_tender: element.is_tender,
                                            task_hold: element.task_hold,
                                            ttstatus: element.ttstatus,
                                        });
                                    } else if (element.is_tender === 0) {
                                        datapull.push({
                                            id: element.id,
                                            uid: element.uid,
                                            gid: element.gid,
                                            tid: element.tid,
                                            pid: element.pid,
                                            ttid: element.ttid,
                                            tpid: element.tpid,
                                            twbsid: element.twbsid,
                                            trolesid: element.trolesid,
                                            tmapid: element.tmapid,
                                            uemail: element.uemail,
                                            uname: element.uname_first + " " + element.uname_last,
                                            gname: element.gname,
                                            tname: element.tname,
                                            tproj_name: element.tproj_name,
                                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                                            ttdecs: element.ttdecs,
                                            tuemail: element.tuemail,
                                            twbsname: element.twbsname,
                                            trolename: element.trolename,
                                            ttender_name: element.ttender_name,
                                            ttender_id: element.ttenderid,
                                            title: element.proj_name + "(" + element.tname + ")",
                                            proj_name: element.proj_name,
                                            work_detail: element.work_detail,
                                            comments: element.comments,
                                            date: date,
                                            start: new Date(element.start),
                                            end: new Date(element.end),
                                            is_locked: element.is_locked,
                                            is_submitted: element.is_submitted,
                                            is_approved: element.is_approved,
                                            strstart: element.start,
                                            strend: element.end,
                                            stime: stime,
                                            etime: etime,
                                            estimated_hours: element.estimated_hours,
                                            created_by: element.created_by,
                                            whours: whours1,
                                            actual_hours: element.actual_hours,
                                            is_tender: element.is_tender,
                                            task_hold: element.task_hold,
                                            ttstatus: element.ttstatus,
                                        });
                                    }
                                }

                                var enableDelete = false
                                if (datapull.length === 0) {
                                    enableDelete = true
                                }

                                this.setState({ data: dataFr, data1: datapull, enableDelete: enableDelete }, () => {
                                    this.handleColumns()
                                    this.handleColumns2()
                                })

                                this.getBillableData()
                            })
                            .catch(error => {
                                // console.log(error)
                                errorMessage(this.props, error)
                            });
                    } else {
                        this.setState({ data: dataFr, data1: dataFr.list_tasks[1][1], }, () => {
                            this.handleColumns()
                            this.handleColumns2()
                        })
                    }
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getTimesheetData(appid) {
        Service.get("/bb/api/task/approve/get_timesheet_data/?id=" + appid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var modifiedData = []
                var ttender_id = []
                var tid = []

                var formatted_data = this.formatData(res.data.list_tasks)

                for (let i = 0; i < formatted_data.length; i++) {
                    const element = formatted_data[i];
                    if (modifiedData.length === 0) {
                        modifiedData.push(element)
                        if (element.ttender_id && element.ttender_id !== null) {
                            ttender_id.push(element.ttender_id)
                        } else if (element.tid && element.tid !== null) {
                            tid.push(element.tid)
                        }
                    } else if (element.ttender_id && !ttender_id.includes(element.ttender_id)) {
                        modifiedData.push(element)
                        ttender_id.push(element.ttender_id)
                    } else if (element.tid && !tid.includes(element.tid)) {
                        modifiedData.push(element)
                        tid.push(element.tid)
                    }
                }

                let list_tasks = JSON.parse(JSON.stringify(formatted_data))
                for (let i = 0; i < modifiedData.length; i++) {
                    const element = modifiedData[i];
                    var detailPanel = []
                    for (let j = 0; j < list_tasks.length; j++) {
                        const element1 = list_tasks[j];
                        if ((element.ttender_id && element1.ttender_id && element1.ttender_id === element.ttender_id) || (element.tid && element1.tid && element1.tid === element.tid)) {
                            element[element1.date] = (!element[element1.date] ? 0 : element[element1.date]) + element1.actual_hours
                            element.total = (!element.total ? 0 : element.total) + element1.actual_hours
                            element1[element1.date] = element1.whours
                            detailPanel.push(element1)
                        }
                    }
                    element.detailPanel = detailPanel
                }
                let over_all_count = 0
                let overall_approved_count = 0
                for (let i = 0; i < modifiedData.length; i++) {
                    const element = modifiedData[i];
                    element.is_checked = true
                    element.is_all_approved = false
                    element.checkbox_enable = false
                    let pending_count = 0
                    for (let j = 0; j < element.detailPanel.length; j++) {
                        const element1 = element.detailPanel[j];
                        over_all_count += 1
                        if (element1.timesheet_approval_status === 0 && element1.checkbox_enable) {
                            pending_count += 1
                            element1.is_checked = true
                            element.checkbox_enable = true
                        } else if ([1].includes(element1.timesheet_approval_status) && element1.checkbox_enable) {
                            element1.is_checked = false
                            element.checkbox_enable = true
                            overall_approved_count += 1
                        }
                    }

                    if (pending_count === 0) {
                        element.is_all_approved = true
                        element.is_checked = false
                    }
                }

                if (over_all_count !== 0 && overall_approved_count !== 0 && over_all_count === overall_approved_count) {
                    for (let i = 0; i < modifiedData.length; i++) {
                        const element = modifiedData[i];
                        element.is_checked = true
                        element.is_all_approved = false
                        for (let j = 0; j < element.detailPanel.length; j++) {
                            const element1 = element.detailPanel[j];
                            element1.is_checked = true
                        }
                    }
                }

                // console.log(modifiedData)
                res.data.modifiedData = modifiedData
                this.setState({
                    timesheetData2: res.data,
                    timesheetLayout: res.data.timesheet_layout,
                    approve_status: res.data.approve_status,
                    isLoading: false,
                    rowLoading: false,
                    enableDelete: formatted_data.length === 0
                }, () => {
                    this.autoAction()
                    this.setState({ disableApprove: this.overallDisableApprove() })
                })
                this.getBillableData()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    formatData(data) {
        var datapull = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            var date = moment(element.start).format('DD/MM/YYYY')
            var stime = moment(element.start).format('HH mm A');
            var etime = moment(element.end).format('HH mm A');
            var whours = moment.duration(moment(element.end).diff(moment(element.start)));
            var whours1 = ''
            if (parseFloat(whours.asHours()) < 0.9) {
                whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
            } else {
                whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
            }

            if (element.is_tender === 1) {
                datapull.push({
                    id: element.id,
                    uid: element.uid,
                    gid: element.gid,
                    tid: element.tid,
                    pid: element.pid,
                    ttid: element.ttid,
                    tpid: element.tpid,
                    twbsid: element.twbsid,
                    trolesid: element.trolesid,
                    tmapid: element.tmapid,
                    uemail: element.uemail,
                    uname: element.uname_first + " " + element.uname_last,
                    gname: element.gname,
                    tname: element.tname,
                    tproj_name: element.tproj_name,
                    ttname: element.ttdecs ? element.ttdecs : element.ttname,
                    ttdecs: element.ttdecs,
                    tuemail: element.tuemail,
                    twbsname: element.twbsname,
                    trolename: element.trolename,
                    ttender_id: element.ttenderid ? element.ttenderid : element.ttender_id,
                    ttender_name: element.ttender_name,
                    title: element.tproj_name + "(" + element.ttdecs ? element.ttdecs : element.ttname + " - " + element.ttdecs + ")",
                    proj_name: element.proj_name,
                    work_detail: element.work_detail,
                    comments: element.comments,
                    date: date,
                    start: new Date(element.start),
                    end: new Date(element.end),
                    is_locked: element.is_locked,
                    is_submitted: element.is_submitted,
                    is_approved: element.is_approved,
                    strstart: element.start,
                    strend: element.end,
                    stime: stime,
                    etime: etime,
                    estimated_hours: element.estimated_hours,
                    created_by: element.created_by,
                    whours: whours1,
                    actual_hours: element.actual_hours,
                    is_tender: element.is_tender,
                    task_hold: element.task_hold,
                    ttstatus: element.ttstatus,
                    timesheet_approval_status: element.timesheet_approval_status,
                    checkbox_enable: element.checkbox_enable
                });
            } else if (element.is_tender === 0) {
                datapull.push({
                    id: element.id,
                    uid: element.uid,
                    gid: element.gid,
                    tid: element.tid,
                    pid: element.pid,
                    ttid: element.ttid,
                    tpid: element.tpid,
                    twbsid: element.twbsid,
                    trolesid: element.trolesid,
                    tmapid: element.tmapid,
                    uemail: element.uemail,
                    uname: element.uname_first + " " + element.uname_last,
                    gname: element.gname,
                    tname: element.tname,
                    tproj_name: element.tproj_name,
                    ttname: element.ttdecs ? element.ttdecs : element.ttname,
                    ttdecs: element.ttdecs,
                    tuemail: element.tuemail,
                    twbsname: element.twbsname,
                    trolename: element.trolename,
                    ttender_name: element.ttender_name,
                    ttender_id: element.ttenderid ? element.ttenderid : element.ttender_id,
                    title: element.proj_name + "(" + element.tname + ")",
                    proj_name: element.proj_name,
                    work_detail: element.work_detail,
                    comments: element.comments,
                    date: date,
                    start: new Date(element.start),
                    end: new Date(element.end),
                    is_locked: element.is_locked,
                    is_submitted: element.is_submitted,
                    is_approved: element.is_approved,
                    strstart: element.start,
                    strend: element.end,
                    stime: stime,
                    etime: etime,
                    estimated_hours: element.estimated_hours,
                    created_by: element.created_by,
                    whours: whours1,
                    actual_hours: element.actual_hours,
                    is_tender: element.is_tender,
                    task_hold: element.task_hold,
                    ttstatus: element.ttstatus,
                    timesheet_approval_status: element.timesheet_approval_status,
                    checkbox_enable: element.checkbox_enable
                });
            }
        }
        return datapull
    }

    getBillableData() {
        var appid = this.props.value;
        Service.get('/bb/api/tasks/estimate/task_billability_hours/?approval=' + appid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    timesheetBillableData: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleColumns() {

        var columns = [
            { title: 'Emp Name', field: 'uname' },

            // { title: 'Projects', field: 'proj_name' },
            // { title: 'Tasks', field: 'tname' },
        ];

        if (this.state.data.list_tasks[0][0][1].field !== 'comment' && this.state.data.comment) {
            columns.push({ title: 'Comment', field: 'comment' });
        }

        for (let i = 1; i < this.state.data.list_tasks[0][0].length; i++) {
            const element = this.state.data.list_tasks[0][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[element.field]) < 4) ? ("error") : (parseInt(rowData[element.field]) > 7) ? ("primary") : ("secondary")} >{rowData[element.field]}</Typography></ThemeProvider>
                }
            )
        }

        var statedata = this.state.data1;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            element[element.date] = element.whours;

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name + " - " + element3.tproj_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === element3.ttname) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttname)
                            }

                        }
                    }
                    // console.log(hours)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element[0].pid,
                tid: element[0].tid,
                ttid: element[0].ttid,
                tpid: element[0].tpid,
                twbsid: element[0].twbsid,
                trolesid: element[0].trolesid,
                tmapid: element[0].tmapid,
                tname: tasks.join(),
                tproj_name: element[0].tproj_name,
                ttname: element[0].ttname,
                tuemail: element[0].tuemail,
                twbsname: element[0].twbsname,
                trolename: element[0].trolename,
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours,
                created_by: element[0].created_by,
                is_tender: element.is_tender,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            const totalDurations11 = wh.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh[0]));
            var minin = (moment.duration(totalDurations11.asMilliseconds()));
            var minin2 = moment.utc(totalDurations11.asMilliseconds()).format("mm")
            jsoncons["total"] = parseInt(minin.asHours()) + ":" + minin2;

            toTData.push(jsoncons);
        }

        var todata = [];
        // console.log(columns)
        todata.push(columns)
        todata.push(toTData)
        this.setState({
            toDia: todata,
        })

    }

    handleColumns2() {
        var columns = [
            {
                title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.proj_name ? rowData.proj_name : rowData.ttender_name + " - " + rowData.tproj_name + " - " + rowData.twbsname)
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.tname ? rowData.tname : rowData.ttdecs ? rowData.ttdecs : rowData.ttname)
            },
            {
                title: 'Status', field: 'task_hold',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus)
            },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Hours Expended', field: 'actual_hours' },
        ];

        var detailPanelColumns = [
            {
                title: 'DS', field: 'tproj_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                // render: rowData => (rowData.tname ? rowData.tname : rowData.ttdecs ? rowData.ttdecs : rowData.ttname)
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.tname ? rowData.tname : rowData.ttdecs ? rowData.ttdecs : rowData.ttname)
            },
            {
                title: 'Status', field: 'task_hold',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus)
            },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Hours Expended', field: 'actual_hours' },
        ]

        var columns1 = [
            {
                title: 'Projects', field: 'projects',
            },
        ];

        var i_temp = 6
        for (let i = 0; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            if (element.field === 'actual_hours') {
                i_temp = i + 1
            }
        }

        for (let i = i_temp; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <Typography color={("primary")}>{rowData[element.field]}</Typography>
                }
            )
            columns1.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <Typography color={("primary")}>{rowData[element.field]}</Typography>
                }
            )
            detailPanelColumns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <Typography color={("primary")}>{rowData[element.field]}</Typography>
                }
            )
        }

        columns1.push(
            {
                title: 'Total', field: 'total',
            }
        )

        var toDia3 = []
        toDia3.push(columns1)

        var data = this.state.data1
        // console.log(data)
        var summarizeData = []
        var overheads = []
        // debugger
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.tid) {
                if (overheads.length === 0) {
                    overheads.push({
                        projects: element.tname,
                        tid: element.tid,
                        [element.date]: element.actual_hours,
                        total: element.actual_hours
                    })
                } else {
                    let overheads_ids = []
                    for (let i = 0; i < overheads.length; i++) {
                        const id = overheads[i];
                        if (!overheads_ids.includes(id.tid)) {
                            overheads_ids.push(id.tid)
                        }
                    }
                    for (let j = 0; j < overheads.length; j++) {
                        const element1 = overheads[j];
                        if (element1.tid === element.tid) {
                            if (element1.hasOwnProperty(element.date)) {
                                element1[element.date] = element1[element.date] + element.actual_hours
                            } else {
                                element1[element.date] = element.actual_hours
                            }
                            element1['total'] = element1['total'] + element.actual_hours
                            break
                        } else if (!overheads_ids.includes(element.tid)) {
                            if (element.tid !== null) {
                                overheads.push({
                                    projects: element.tname,
                                    tid: element.tid,
                                    [element.date]: element.actual_hours,
                                    total: element.actual_hours
                                })
                                break
                            }
                        } else {
                            continue
                        }
                    }
                }
            } else {
                if (summarizeData.length === 0) {
                    summarizeData.push({
                        projects: element.ttender_name,
                        ttender_id: element.ttender_id,
                        [element.date]: element.actual_hours,
                        total: element.actual_hours
                    })
                } else {
                    let tender_ids = []
                    for (let i = 0; i < summarizeData.length; i++) {
                        const id = summarizeData[i];
                        if (!tender_ids.includes(id.ttender_id)) {
                            tender_ids.push(id.ttender_id)
                        }
                    }
                    for (let j = 0; j < summarizeData.length; j++) {
                        const element1 = summarizeData[j];
                        if (element1.ttender_id === element.ttender_id) {
                            if (element1.hasOwnProperty(element.date)) {
                                element1[element.date] = element1[element.date] + element.actual_hours
                            } else {
                                element1[element.date] = element.actual_hours
                            }
                            element1['total'] = element1['total'] + element.actual_hours
                            break
                        } else if (!tender_ids.includes(element.ttender_id)) {
                            if (element.ttender_id !== null) {
                                summarizeData.push({
                                    projects: element.ttender_name,
                                    ttender_id: element.ttender_id,
                                    [element.date]: element.actual_hours,
                                    total: element.actual_hours
                                })
                                break
                            }
                        } else {
                            continue
                        }
                    }
                }
            }
        }

        toDia3.push([...summarizeData, ...overheads])
        // console.log(toDia3)
        var todata = [];
        var detailPanelData = []
        todata.push(columns);
        detailPanelData.push(detailPanelColumns);

        var is_approve = true;
        var is_reject = true;
        for (let i = 0; i < this.state.data1.length; i++) {
            const element = this.state.data1[i];

            element[element.date] = element.whours
            if (this.state.data.approve_status === 0) {
                element.is_checked = true
            }
            if (element.is_approved !== 1) {
                is_approve = false;
            }
            if (element.is_approved !== 2) {
                is_reject = false;
            }
        }

        todata.push(this.state.data1)
        detailPanelData.push(this.state.data1)

        // console.log(todata)

        this.setState({
            toDia2: todata,
            toDia3: toDia3,
            detailPanelData: detailPanelData,
            is_approved: is_approve,
            is_reject: is_reject,
            isLoading: false,
            rowLoading: false,
        }, () => { this.autoAction() })
    }

    autoAction() {
        if (this.state.data.approve_status === 0 && autoActionAtempt) {
            autoActionAtempt = false
            const query = new URLSearchParams(this.props.querySearch);
            if (query.get('action') === "approve") {
                this.approveType(1)
            } else if (query.get('action') === "reject") {
                mailtype = "reject"; this.setState({ openSub: true })
            }
        }
    }

    deleteItem() {
        Service.delete("/bb/api/task/approve/" + this.props.value + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {

                errorMessage(this.props, error)

            });
    }

    handleMail(to, sub, body, reply_to) {
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {

                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    handleReview() {

        var sub = user.last_name + ", " + user.first_name + " requested you to review your timesheet"
        var body = "Hi " + this.state.data.uname + "\n   You need to do the following comment\n\n  Comment : " + this.state.postComment +
            "\n\n Note : If you edit the existing task, no need to submit timesheet for approval again. But, if you add any new tasks, then please submit for approval." +
            "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id

        this.handleMail(this.state.data.uemail, sub, body, user.email)

        this.setState({ openSub: false })
    }

    handleRejectMail() {

        // var sub = user.last_name + ", " + user.first_name + " rejected your timesheet"
        // var body = "Hi " + this.state.data.uname + "\n   You need to do the following comment\n\n  Comment : " + this.state.postComment +
        //     "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id


        // if (this.state.notifyUser) {
        //     // this.handleMail(this.state.data.uemail, sub, body, user.email)
        // }

        this.setState({ openSub: false })
    }

    handleApproveMail() {

        // var sub = user.last_name + ", " + user.first_name + " approved your timesheet"
        // var body = "Hi " + this.state.data.uname + "\n   Your Timesheet approved by " + user.last_name + ", " + user.first_name +
        //     "\n\n   Click on the link below to view your timesheet\n        http://" + window.location.host + "/approvalv/" + this.state.data.id

        // if (this.state.notifyUser) {
        //     // alert("qw")
        //     // this.handleMail(this.state.data.uemail, sub, body, user.email)
        // }

        this.setState({ openSub: false })
    }

    handleCommSubmit() {
        if (mailtype === 'request') {
            this.handleReview();
        } else if (mailtype === 'reject') {
            this.approveType(2); //actually rejecting
            this.setState({ openSub: false })
        }
    }

    handleApprove(stat) {
        this.setState({ isLoading: true })
        var dataTo = [];
        var toDia2 = this.state.toDia2;
        toDia2[0].splice(0, 1, { title: 'Project', field: 'ttender_name' });
        dataTo.push(this.state.toDia);
        dataTo.push(toDia2);
        var upTask = [];

        var postData = this.state.data;
        postData.replied_by = user.id;
        postData.replied_on = new Date();
        postData.list_tasks = dataTo;
        postData.approve_status = stat;

        if (stat === 2) {
            postData.remark = this.state.postComment
        }

        upTask.push(postData)

        for (let i = 0; i < this.state.data1.length; i++) {
            const element = this.state.data1[i];
            upTask.push(element.id)
        }
        // console.log(upTask)
        var url = ""
        if (stat === 1) {
            url = "/bb/api/task/approve/approve_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        } else if (stat === 2) {
            url = "/bb/api/task/approve/reject_timesheet/?notify=" + (this.state.notifyUser ? 1 : 0)
        }
        // console.log(upTask)
        Service.patch(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: upTask,
        })
            .then(res => {
                if (this.props.refreshCount) {
                    this.props.refreshCount()
                }

                this.setState({ openSub: false, })
                this.fetchOps();

                if (stat === 2) {
                    this.props.enqueueSnackbar('Timesheet rejected.', {
                        variant: 'success'
                    });
                    // this.handleRejectMail();
                } else if (stat === 1) {
                    this.props.enqueueSnackbar('Timesheet approved.', {
                        variant: 'success'
                    });
                    // this.handleApproveMail();
                }

            })
            .catch(error => {
                this.fetchOps();
                errorMessage(this.props, error)
            });

    }

    getTotal(data) {
        var total = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.total) {
                total += element.total
            }
        }
        return total
    }

    handleSelect(e, index) {
        var timesheetData2 = this.state.timesheetData2
        timesheetData2.modifiedData[index].is_checked = e.target.checked
        for (let i = 0; i < timesheetData2.modifiedData[index].detailPanel.length; i++) {
            const element = timesheetData2.modifiedData[index].detailPanel[i];
            if ([0, 1].includes(element.timesheet_approval_status)) {
                element.is_checked = e.target.checked
            }
        }
        this.setState({ timesheetData2: timesheetData2 }, () => {
            this.disableApprove()
        })
    }

    handleIndivSelect(e, index, panelIndex) {
        var timesheetData2 = this.state.timesheetData2
        timesheetData2.modifiedData[index].detailPanel[panelIndex].is_checked = e.target.checked
        if (!e.target.checked) {
            timesheetData2.modifiedData[index].is_checked = false
        }

        let is_checked = timesheetData2.modifiedData[index].detailPanel.filter((item) => item.is_checked).length
        let is_pending = timesheetData2.modifiedData[index].detailPanel.filter((item) => [0, 1].includes(item.timesheet_approval_status)).length

        if (is_checked && is_pending && is_checked === is_pending) {
            timesheetData2.modifiedData[index].is_checked = true
        } else {
            timesheetData2.modifiedData[index].is_checked = false
        }
        this.setState({ timesheetData2: timesheetData2 }, () => {
            this.disableApprove()
        })
    }

    disableApprove() {
        let timesheetData2 = this.state.timesheetData2
        let disable = false
        for (let i = 0; i < timesheetData2.modifiedData.length; i++) {
            const element = timesheetData2.modifiedData[i];
            for (let j = 0; j < element.detailPanel.length; j++) {
                const element1 = element.detailPanel[j];
                if (element1.is_checked && element1.timesheet_approval_status === 1 && element1.checkbox_enable) {
                    disable = true
                    break
                }
            }
        }
        this.setState({ disableApprove: disable }, () => {
            this.overallDisableApprove()
        })
    }

    overallDisableApprove() {
        let timesheetData2 = this.state.timesheetData2
        let count = 0
        let item_count = 0
        for (let i = 0; i < timesheetData2.modifiedData.length; i++) {
            const element = timesheetData2.modifiedData[i];
            for (let j = 0; j < element.detailPanel.length; j++) {
                const element1 = element.detailPanel[j];
                item_count += 1
                if (element1.timesheet_approval_status === 1 && element1.checkbox_enable) {
                    count += 1
                }
            }
        }
        if (count !== 0 && item_count !== 0 && count === item_count) {
            this.setState({ disableApprove: true })
            return true
        } else {
            // this.setState({ disableApprove: false })
            return false
        }
    }

    handleOpen(i) {
        if (this.state.timesheetLayout === 2) {
            let timesheetData2 = this.state.timesheetData2
            timesheetData2.modifiedData[i].is_open = !timesheetData2.modifiedData[i].is_open
            this.setState({ timesheetData2: timesheetData2, })
        } else {
            this.handleOpen0(i)
        }
    }

    handleOpen0(i) {
        let toDia3 = this.state.toDia3
        toDia3[1][i].is_open = !toDia3[1][i].is_open
        this.setState({ toDia3: toDia3 })
    }

    approveType(stat) {
        if ([0, 1].includes(this.state.timesheetLayout)) {
            this.handleApprove(stat)
            this.setState({ isLoading: true })
        } else {
            this.partialApprove(stat)
        }
    }

    partialApprove(stat) {
        var timesheetData2 = this.state.timesheetData2
        var selectedIds = []
        for (let i = 0; i < timesheetData2.modifiedData.length; i++) {
            const element = timesheetData2.modifiedData[i];
            for (let j = 0; j < element.detailPanel.length; j++) {
                const element1 = element.detailPanel[j];
                if (element1.is_checked && [0, 1].includes(element1.timesheet_approval_status)) {
                    selectedIds.push(element1.id)
                }
            }
        }

        if (selectedIds.length === 0) {
            this.props.enqueueSnackbar('No task selected for approval.', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true })
            var url = "/bb/api/task/approve/partial_approval/?notify=" + (this.state.notifyUser ? 1 : 0)
            var postData = {
                approval_id: this.props.value,
                item_id: selectedIds,
                status: stat
            }
            if (stat === 2) {
                postData.remark = this.state.postComment
            }
            Service.patch(url, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    if (this.props.refreshCount) {
                        this.props.refreshCount()
                    }

                    this.setState({ openSub: false, })
                    this.fetchOps();

                    if (stat === 1) {
                        this.props.enqueueSnackbar('Timesheet is approved successfully.', {
                            variant: 'success'
                        });
                    } else {
                        this.props.enqueueSnackbar('Timesheet is rejected successfully.', {
                            variant: 'success'
                        });
                    }
                })
                .catch(error => {
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(error.data.message, {
                            variant: 'warning'
                        });
                    } else {
                        this.fetchOps();
                        errorMessage(this.props, error)
                    }
                    console.log(error)
                });
        }

        // this.setState({ isLoading: true })
        // var dataTo = [];
        // var toDia2 = this.state.toDia2;
        // toDia2[0].splice(0, 1, { title: 'Project', field: 'ttender_name' });
        // toDia2[1] = toDia2[1].filter((item) => item.is_checked)
        // dataTo.push(this.state.toDia);
        // dataTo.push(toDia2);
        // var upTask = [];
        // var postData = this.state.data;
        // postData.replied_by = user.id;
        // postData.replied_on = new Date();
        // postData.list_tasks = dataTo;
        // postData.approve_status = stat;

        // upTask.push(postData)

        // for (let i = 0; i < this.state.data1.length; i++) {
        //     const element = this.state.data1[i];
        //     if (element.is_checked) {
        //         upTask.push(element.id)
        //     }
        // }
        // console.log(upTask)
        // var url = "/bb/api/task/approve/partial_approve_and_reject/?notify=" + (this.state.notifyUser ? 1 : 0)

        // Service.patch(url, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: upTask,
        // })
        //     .then(res => {
        //         if (this.props.refreshCount) {
        //             this.props.refreshCount()
        //         }

        //         this.setState({ openSub: false, })
        //         this.fetchOps();

        //         this.props.enqueueSnackbar('Timesheet is partially approved.', {
        //             variant: 'success'
        //         });
        //     })
        //     .catch(error => {
        //         this.fetchOps();
        //         errorMessage(this.props, error)
        //     });
    }

    enableAction() {
        if (this.state.timesheetLayout === 2) {
            let data = this.state.timesheetData2
            let go = false
            for (let i = 0; i < data.modifiedData.length; i++) {
                const element = data.modifiedData[i];
                if (element.checkbox_enable) {
                    go = true
                    break
                }
            }
            if (go && [0, 1, 3, 4, 5].includes(this.state.approve_status) && (access && access.access && access.access.l2a && access.access.l2a.l2a_b)) {
                return true
            }
            //  else if (go && (access && access.access && access.access.l2a && access.access.l2a.l2a_b)) {
            //     return true
            // } 
            else {
                return false
            }
        } else if ((this.state.approve_status === 0) && (access && access.access && access.access.l2a && access.access.l2a.l2a_b)) {
            return true
        } else {
            return false
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ openSub: false })
        };

        const handleComm = (e) => {
            this.setState({ postComment: e.target.value })
        };

        const handleChange = (event) => {
            this.setState({ [event.target.name]: event.target.checked });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {this.state.rowLoading ? <CircularProgress color='primary' /> :
                            <div>
                                {this.state.timesheetLayout === 0 ?
                                    <TimesheetTemplate0
                                        toDia={this.state.toDia}
                                        toDia2={this.state.toDia2}
                                        toDia3={this.state.toDia3}
                                        data={this.state.data}
                                        mini_table_data_start={mini_table_data_start}
                                        table_data_start={table_data_start}
                                        timesheetLayout={this.state.timesheetLayout}
                                    /> :
                                    this.state.timesheetLayout === 1 ?
                                        <TimesheetTemplate1
                                            toDia={this.state.toDia}
                                            toDia2={this.state.toDia2}
                                            toDia3={this.state.toDia3}
                                            handleOpen={this.handleOpen}
                                            data={this.state.data}
                                            mini_table_data_start={mini_table_data_start}
                                            table_data_start={table_data_start}
                                            timesheetLayout={this.state.timesheetLayout}
                                        /> :
                                        <TimesheetTemplate2
                                            handleSelect={this.handleSelect}
                                            handleIndivSelect={this.handleIndivSelect}
                                            handleOpen={this.handleOpen}
                                            data={this.state.timesheetData2}
                                            timesheetLayout={this.state.timesheetLayout}
                                        />}
                            </div>
                        }

                        {this.enableAction() ? (
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.notifyUser}
                                            onChange={handleChange}
                                            name="notifyUser"
                                            color="primary"
                                        />
                                    }
                                    label={"Notify " + (this.state.timesheetData2 ? this.state.timesheetData2.uname : (this.state.toDia[1][0] ? this.state.toDia[1][0].uname : "")) + " about your response"}
                                />
                            </Box>
                        ) : (null)}

                        {this.state.timesheetBillableData && this.state.timesheetBillableData.non_billable_hours ?
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Box5 sx={{ color: 'warning.main' }} fontWeight={500}>This timesheet request contains {this.state.timesheetBillableData.non_billable_hours + "(" + parseFloat(((this.state.timesheetBillableData.non_billable_hours / (this.state.timesheetBillableData.billable_hours ? (this.state.timesheetBillableData.billable_hours + this.state.timesheetBillableData.non_billable_hours) : this.state.timesheetBillableData.non_billable_hours)) * 100).toFixed(2)) + "%)"} non billable hours.</Box5>
                            </Box>
                            : null}

                        {([1, 2, 4, 5, 6, 7].includes(this.state.approve_status)) ?
                            <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                                {(this.state.approve_status === 4) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '2px', marginLeft: '10px', marginRight: '10px' }}  ><Box5 sx={{ color: 'warning.main' }} fontWeight="fontWeightBold">{"Time Sheet is partially approved" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box5></Typography></ThemeProvider>
                                ) : (this.state.approve_status === 5) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '2px', marginLeft: '10px', marginRight: '10px' }}  ><Box5 sx={{ color: 'warning.main' }} fontWeight="fontWeightBold">{"Time Sheet is partailly rejected" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box5></Typography></ThemeProvider>
                                ) : (this.state.approve_status === 1) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="primary" ><Box fontWeight="fontWeightBold">{"Time Sheet is approved" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box></Typography></ThemeProvider>
                                ) : (this.state.approve_status === 2) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="error" ><Box fontWeight="fontWeightBold">{"Time Sheet is rejected" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box></Typography></ThemeProvider>
                                ) : (this.state.approve_status === 6) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }}  ><Box5 sx={{ color: 'warning.main' }} fontWeight="fontWeightBold">{"Time Sheet is both approved and rejected" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box5></Typography></ThemeProvider>
                                ) : (this.state.approve_status === 7) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="error" ><Box fontWeight="fontWeightBold">{"Time Sheet is deleted"}</Box></Typography></ThemeProvider>
                                ) : (null)}
                            </Box> : null
                        }

                        {this.enableAction() ? (
                            <ThemeProvider theme={bbColorTheme} >
                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                        onClick={() => this.approveType(1)}
                                        disabled={this.state.disableApprove}
                                    >
                                        Approve Timesheet
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                        onClick={() => { mailtype = "request"; this.setState({ openSub: true }) }}
                                    >
                                        Request Review
                                    </Button>
                                    <ThemeProvider theme={bbColorThemered} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                            onClick={() => { mailtype = "reject"; this.setState({ openSub: true }) }}
                                        >
                                            Reject Timesheet
                                        </Button>
                                    </ThemeProvider>

                                    {(this.state.enableDelete) ? (
                                        <ThemeProvider theme={bbColorThemered} >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: '10px', marginRight: '10px' }}
                                                onClick={() => { this.deleteItem() }}
                                            >
                                                Delete Timesheet
                                            </Button>
                                        </ThemeProvider>
                                    ) : (null)}

                                </Box>
                            </ThemeProvider>
                        ) : null}

                        <Dialog open={this.state.openSub} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Comment</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Type a comment for the user.
                                </DialogContentText>
                                <TextField id="outlined-basic3" label="Comment" variant="outlined"
                                    value={this.state.postComment}
                                    onChange={handleComm}
                                    fullWidth
                                    style={{ marginTop: '15px' }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => this.handleCommSubmit()} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(WorkHours));



